.container
  @apply w-11/12 md:w-full mx-auto
  
.root
  @apply rounded-md text-center bg-amber-700 p-2 grid

.content
  @apply text-white font-sans font-light

  a
    @apply underline font-normal