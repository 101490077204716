/* purgecss start ignore */

.root{
  margin-top:1rem;
  width:100%
}

@media (min-width: 768px){
  .root{
    margin-left:0.625rem;
    margin-right:0.625rem;
    width:auto
  }
}

.root{
  cursor:pointer
}

.title{
  margin-top:1rem;
  margin-bottom:0.5rem;
  text-transform:capitalize
}

/* purgecss end ignore */