/* purgecss start ignore */

.feature{
  margin-bottom: 1rem;
  display: flex;
  align-items: flex-start
}

@media (min-width: 1024px){
  .feature{
    grid-column: span 1 / span 1
  }
}

.featureIconContainer{
  flex-shrink: 0
}

.featureIcon{
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-text-opacity: 1;
  color: rgba(0, 124, 132, var(--tw-text-opacity))
}

.modal{
  height: 75%;
  height: 100vh;
  width: 100%;
  overflow: auto;
  -ms-scroll-chaining: none;
      overscroll-behavior: contain
}

@media (min-width: 768px){
  .modal{
    height: auto
  }
}

@media (min-width: 1280px){
  .modal{
    width: 75%
  }
}

/* purgecss end ignore */