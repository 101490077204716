// Features

.feature
  @apply flex lg:col-span-1 items-start mb-4

.featureIconContainer
  @apply flex-shrink-0

.featureIcon
  @apply text-base text-subkit-primary

// Modal

.modal
  @apply w-full xl:w-9/12 h-3/4 md:h-auto overflow-auto overscroll-contain h-screen
