/* purgecss start ignore */

.container{
  margin-left: auto;
  margin-right: auto;
  width: 91.666667%
}

@media (min-width: 768px){
  .container{
    width: 100%
  }
}

.root{
  display: grid;
  border-radius: 0.375rem;
  --tw-bg-opacity: 1;
  background-color: rgba(180, 83, 9, var(--tw-bg-opacity));
  padding: 0.5rem;
  text-align: center
}

.content{
  font-family: "IBM Plex Sans", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 300;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity))
}

.content a{
  font-weight: 400;
  text-decoration: underline
}

/* purgecss end ignore */